$startWidth: 100px;
$triggerHeight: 50px;
$iconSize: 24px;
div#AppFrameNav {
	width: var(--navigation-sidebar-width);
	min-width: var(--navigation-sidebar-width);
	transition: width var(--mainmenu-transition-time), min-width var(--mainmenu-transition-time);
	z-index: 99;
	overflow: hidden;

	&[hidden] {
		--navigation-sidebar-width: 0;
	}
}

.menu-items {
	// min-height: calc(100% - 1px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	padding-left: 0;
	padding-bottom: 1rem;
	padding-top: 1.5rem;
	height: 100%;
}

.mainmenu-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $startWidth;
	padding: 1rem 0;

	// position: relative;

	.mainmenu-item-trigger svg,
	.mainmenu-item-trigger img {
		width: $iconSize;
		height: $iconSize;
		object-fit: cover;
		transition: transform 250ms, fill 250ms, filter 250ms, stroke 250ms;
		z-index: 1;

		&:not([filltype="stroke"]):not([fill="none"]) {
			fill: #ffffff;
		}

		&[filltype="stroke"] path {
			transition: stroke 250ms;
			stroke: #ffffff;
		}
	}

	img.navigation-sidebar__logo {
		height: 28px;
		width: 28px;
	}

	.bottom-scroll-shadow {
		display: none;
		width: calc(var(--navigation-sidebar-width) - #{$startWidth});
		left: $startWidth;
		transition: opacity 250ms;
		position: fixed;
	}

	.mainmenu-item-trigger {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		// padding: 1rem 0;
		height: $triggerHeight;
		position: relative;
		cursor: pointer;
		flex-direction: column;
		gap: 0.5em;

		.mainmenu-item-trigger_title {
			color: #ffffff;
			font-size: 0.82em;
			transition: opacity 250ms, color 250ms, filter 250ms;
			opacity: 0.7;
			user-select: none;
		}

		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	height: calc(#{$iconSize} + 26px);
		// 	width: calc(#{$iconSize} + 26px);
		// 	background-color: var(--tertiary);
		// 	border-radius: 1rem;
		// 	z-index: 0;
		// 	opacity: 0;
		// 	transform: scale(0.75);
		// 	transition: opacity 250ms, transform 250ms;
		// 	box-shadow: inset 2px 2px 2px 2px #0000008c;
		// }

		&::before {
			content: "";
			height: 15px;
			width: 15px;
			position: absolute;
			left: 0rem;
			background-color: var(--accent, --accent);
			border-radius: 50%;
			align-self: center;
			transform: translateX(-50%);
			box-shadow: 0 0 10px 3px var(--accent, --accent);
			opacity: 0;
			transition: opacity 250ms;
		}

		// &::after {
		// 	content: attr(data-content);
		// 	position: absolute;
		// 	padding: 0.5rem;
		// 	background-color: var(--secondary);
		// 	color: #ffffff;
		// 	left: $startWidth;
		// 	opacity: 0;
		// 	transform: translateX(-25%);
		// 	transition: opacity 100ms, transform 250ms;
		// }

		// &::after {
		// 	left: 100px;
		// 	transform: translateX(-23px) rotate(270deg);
		// 	transition: opacity 100ms;
		// 	content: "";
		// 	position: absolute;
		// 	width: 0;
		// 	height: 0;
		// 	border-left: 15px solid transparent;
		// 	border-right: 15px solid transparent;
		// 	border-bottom: 15px solid var(--main1);
		// 	clear: both;
		// 	opacity: 0;
		// }

		&:hover {
			// &::after {
			// 	opacity: 1;
			// 	transform: translateX(0);
			// }

			.mainmenu-item-trigger_title {
				opacity: 1;
				color: $sidebarColorAccent;
				filter: brightness(0.8);
			}

			svg:not([filltype="stroke"]):not([fill="none"]) {
				fill: $sidebarColorAccent;
				filter: brightness(0.8);
			}

			svg[filltype="stroke"],
			svg[fill="none"] {
				path {
					stroke: $sidebarColorAccent;
					filter: brightness(0.8);
				}
			}
		}
	}

	.mainmenu-item__secondary {
		// opacity: 0;
		// transform: translateX(-100%);
		// transition: transform var(--mainmenu-transition-time), opacity 150ms, width var(--mainmenu-transition-time), display 0ms, padding-left 250ms;
		flex-direction: column;
		position: fixed;
		top: 0;
		color: #ffffff;
		left: $startWidth;
		padding: 0;
		padding-top: 35px;

		background-color: var(--tertiary);
		// background: linear-gradient(90deg, rgba(0, 0, 0, 0.182), #00000038, 15%, var(--tertiary));
		// border-right: 1px solid #797979;
		height: 100%;
		overflow: hidden;
		visibility: hidden;
		// display: none;
		// width: 0px;
		// width: calc(var(--navigation-sidebar-width) - #{$startWidth});
		width: calc(400px - #{$startWidth}) !important;
		box-shadow: 0px 0px 5px 1px #0000007a;

		.mainmenu-item__secondary__header {
			padding-right: 2rem;
			display: flex;
			justify-content: space-between;

			.mainmenu-item__secondary__header__title {
				color: $white;
				font-weight: 600;
				font-size: 1.75rem;
			}
		}

		.mainmenu-item__secondary__actions {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		.search-trigger,
		.Polaris-Navigation__Action {
			background: transparent;
			border: none;
			cursor: pointer;
		}

		.section-serch {
			padding: 0.75rem 0;
			width: 100%;
		}
		.mainmenu-item__secondary__item {
			padding: 0.75rem 0;
			display: flex;
			align-items: center;
			flex: 1;
			justify-content: space-between;
			cursor: pointer;
			height: 40px;
			color: #ffffff;

			.Polaris-Navigation__Action {
				opacity: 0;
				transition: opacity 250ms;
			}
			> div {
				display: flex;
				gap: 1rem;
				align-items: center;
			}

			&,
			.Polaris-Icon,
			svg {
				transition: fill 250ms, color 250ms;
			}

			.Polaris-Icon {
				margin: 0;
			}

			svg,
			.Polaris-Icon,
			img {
				width: 20px;
				height: 20px;
				fill: #ffffff;
			}

			&:hover {
				color: $sidebarColorAccent;

				.Polaris-Navigation__Action {
					opacity: 1;
				}

				svg,
				.Polaris-Icon {
					fill: $sidebarColorAccent;
				}
			}

			&.active {
				color: $sidebarColorAccent;
				font-weight: 600;
			}
		}
	}
	&.animating .mainmenu-item__secondary {
		visibility: visible !important;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&.url-active {
		.mainmenu-item-trigger::before {
			opacity: 1;
		}

		.mainmenu-item-trigger_title {
			opacity: 1;
			color: $sidebarColorAccent;
		}

		.mainmenu-item-trigger {
			svg:not([filltype="stroke"]):not([fill="none"]) {
				fill: $sidebarColorAccent;
			}

			svg[filltype="stroke"],
			svg[fill="none"] {
				path {
					stroke: $sidebarColorAccent;
				}
			}
		}
	}
	&.active {
		.mainmenu-item-trigger::after {
			opacity: 1;
			transform: scale(1);
		}
		.mainmenu-item__secondary {
			display: initial;
			overflow: auto;
			width: calc(var(--navigation-sidebar-width) - #{$startWidth});
			// transition: transform var(--mainmenu-transition-time), opacity 150ms, width var(--mainmenu-transition-time), display 250ms, padding-left 250ms;
			visibility: visible;

			.mainmenu-item__secondary__items {
				pointer-events: auto;

				.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action {
					padding: 0;
					margin: 0;
				}
			}
		}

		.mainmenu-item-trigger {
			svg:not([filltype="stroke"]):not([fill="none"]) {
				fill: var(--accent);
			}

			svg[filltype="stroke"],
			svg[fill="none"] {
				path {
					stroke: var(--accent);
				}
			}
		}
	}

	.mainmenu-item__secondary__items {
		// margin-top: 1rem;

		.workspace-items {
			// padding-left: 1rem;
		}

		.Polaris-Navigation__Section {
			margin-bottom: 1rem;
			padding-top: 0 !important;
		}

		.Polaris-Navigation__SectionHeading {
			padding-left: 0 !important;
		}
	}
}
#AppFrameNav:not(.have_active) .mainmenu-item.url-active {
	.mainmenu-item-trigger::after {
		opacity: 1;
		transform: scale(1);
	}
}

.Polaris-Navigation__Item {
	margin: 0;
	position: relative;
	padding: 0.75rem 0;
	font-size: 1.3rem;
	display: flex;
	gap: 1rem;
	align-items: center;

	// &:before {
	// 	content: "";
	// 	height: 15px;
	// 	width: 15px;
	// 	position: absolute;
	// 	left: -1.5rem;
	// 	background-color: $sidebarColorAccent;
	// 	border-radius: 50%;
	// 	align-self: center;
	// 	transform: translateX(-50%);
	// 	box-shadow: 0 0 10px 3px $sidebarColorAccent;
	// 	opacity: 0;
	// }

	&:focus,
	&:hover,
	&:active,
	&.active {
		&:before {
			opacity: 1;
		}
	}

	&:active,
	&.active {
		color: $sidebarColorAccent;
	}

	&:hover {
		color: #ffffff;
		background: none !important;
	}

	&.Polaris-Navigation__Item--selected.Polaris-Navigation--subNavigationActive {
		&:before {
			opacity: 1;
		}
	}

	// .Polaris-Navigation__Item {
	// 	padding: 0.35rem 0.8rem;
	// 	font-size: 1.3rem;
	// }
}
body {
	main#AppFrameMain {
		padding-left: var(--navigation-sidebar-width) !important;

		@media (max-width: 767px) {
			padding-left: 0 !important;
		}
	}

	&.nav-active {
		--navigation-sidebar-width: 400px;
	}
}

.mainmenu-item__secondary__collapse-btn {
	position: fixed;
	left: 100px;
	top: 10px;
	cursor: pointer;
	transition: transform var(--mainmenu-transition-time), left var(--mainmenu-transition-time);
	border-radius: 50%;
	transform: translateX(-50%);

	z-index: 999;
	filter: drop-shadow(1px 1px 5px #00000030);
	background-color: var(--secondary);
	visibility: hidden;
	pointer-events: none;

	.Polaris-Icon {
		width: 30px;
		height: 30px;
		fill: #ffffff;
	}

	&:hover {
		transform: translateX(-50%), scale(1.2);
	}
}
body.nav-selected {
	.mainmenu-item__secondary__collapse-btn {
		left: var(--navigation-sidebar-width);
		transform: translateX(-50%);
		visibility: visible;
		pointer-events: auto;
		// box-shadow: $boxShadow;
		// background: #ffffff;
	}
}
body.nav-active {
	.mainmenu-item__secondary__collapse-btn {
		left: var(--navigation-sidebar-width);
		transform: translateX(-50%) rotateY(180deg);
		visibility: visible;
		pointer-events: auto;
		// box-shadow: $boxShadow;
		// background: #ffffff;
	}

	.mainmenu-item.active {
		.mainmenu-item-trigger::after {
			opacity: 1;
		}

		.mainmenu-item-trigger_title {
			opacity: 1;
			color: $sidebarColorAccent;
		}

		.mainmenu-item__secondary {
			padding-right: 2rem;
			padding-left: 2rem;
		}
		.bottom-scroll-shadow {
			display: flex;
		}
	}
}

.Polaris-Navigation__RollupSection {
	list-style: none;
}

.mainmenu-item__secondary__searchbar {
	margin: 1rem 0;
	margin-bottom: 2rem;
}

.mainmenu-item__secondary__search-actions_wrapper {
	margin: 2rem 0;
	display: flex;
	flex-direction: row;
	gap: 1rem;

	.mainmenu-item__secondary__searchbar {
		margin: 0;
	}

	.Polaris-Navigation__Action {
		padding: 0;
		display: flex !important;
		transition: color;

		.Polaris-Icon {
			height: 2.5rem;
			width: 2.5rem;
		}
	}
}

.mainmenu-item__secondary__actions {
	.Polaris-Navigation__Action {
		display: flex;
		align-items: center;
		gap: 1rem;
		color: #ffffff;

		.Polaris-Icon,
		svg {
			fill: #ffffff;
		}
	}
}
.workspace-section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.Polaris-Navigation__Action .Polaris-Icon {
		fill: #ffffff;
	}

	> div:not([class]),
	> button.Polaris-Navigation__Action {
		display: flex;
		align-items: center;
	}
}
.workspace-section-title {
	font-weight: 500;
	color: #cccedd;
	flex: 1 1 auto;
	line-height: 2rem;
	font-size: 1.35rem;
	text-transform: uppercase;
}

.navigation-sidebar {
	height: 100%;
}

.Polaris-Navigation__PrimaryNavigation {
	scrollbar-width: none;
}
.Polaris-Navigation
	.Polaris-Navigation__PrimaryNavigation.Polaris-Scrollable.Polaris-Scrollable--vertical.Polaris-Scrollable--verticalHasScrolling
	div.navigation-sidebar,
.Polaris-Navigation {
	background: $sidebarColorBg !important;
	background-color: $sidebarColorBg !important;
	scrollbar-width: none;

	.Polaris-Navigation__Text {
		color: $sidebarColor;
		font-weight: 600;
	}

	.Polaris-Navigation__SectionHeading span.Polaris-Navigation__Text {
		font-weight: 500;
		color: #cccedd;
	}

	.Polaris-Navigation__Item {
		margin: 0;
		position: relative;
		padding: 0.75rem 0;
		font-size: 1.3rem;
		display: flex;
		gap: 1rem;
		align-items: center;

		// &:before {
		// 	content: "";
		// 	height: 15px;
		// 	width: 15px;
		// 	position: absolute;
		// 	left: -1.5rem;
		// 	background-color: $sidebarColorAccent;
		// 	border-radius: 50%;
		// 	align-self: center;
		// 	transform: translateX(-50%);
		// 	box-shadow: 0 0 10px 3px $sidebarColorAccent;
		// 	opacity: 0;
		// }

		&:focus,
		&:hover,
		&:active,
		&.active {
			&:before {
				opacity: 1;
			}
		}

		&:active,
		&.active {
			color: $sidebarColorAccent;
		}

		&:hover {
			color: #ffffff;
			background: none !important;
		}

		&.Polaris-Navigation__Item--selected.Polaris-Navigation--subNavigationActive {
			&:before {
				opacity: 1;
			}
		}

		// .Polaris-Navigation__Item {
		// 	padding: 0.35rem 0.8rem;
		// 	font-size: 1.3rem;
		// }
	}
	@media (min-width: 40em) {
		.Polaris-Navigation__SectionHeading {
			font-size: 1.35rem;
			padding-left: 0.75rem;
		}
	}

	.Polaris-Navigation__SectionHeading {
		.Polaris-Navigation__Action {
			.Polaris-Icon {
				height: 1.75rem;
				width: 1.75rem;

				&,
				svg {
					fill: #ffffff;
				}
			}
		}
	}
}
.Polaris-Frame__TopBar {
	width: calc(100% - var(--navigation-sidebar-width));
	margin-left: var(--navigation-sidebar-width);
	transition: margin-left var(--mainmenu-transition-time), width var(--mainmenu-transition-time);

	@media screen and (max-width: 47.99em) {
		margin-left: 0;
	}
}

body[data-lock-scrolling] {
	.Polaris-Frame__TopBar {
		@media screen and (max-width: 47.99em) {
			margin-left: var(--navigation-sidebar-width);
		}
	}
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Navigation:not(.Polaris-Frame__Navigation--newDesignLanguage) {
	top: 0 !important;
	z-index: 999;
	height: 100%;
}
@media (min-width: 48.0625em) {
	.Polaris-Navigation {
		max-width: var(--navigation-sidebar-width);
		transition: max-width 0.5s, width 0.5s, min-width 0.5s;
	}
}
.Polaris-TopBar-SearchField__Input,
.Polaris-TopBar-SearchField__Backdrop,
.Polaris-TopBar-SearchField__Backdrop::after {
	border-radius: 2rem;
}
.Polaris-TopBar-SearchField__Backdrop,
.Polaris-TopBar-SearchField__Input {
	background: var(--secondary) !important;
	border-radius: 2rem;
}
.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input,
.Polaris-TopBar-SearchField__Input:focus {
	border: none;
	color: var(--p-text, #212b36);
}
.Polaris-TopBar-SearchField__Input {
	color: $textColor;
}
@media (min-width: 48.0625em) {
	.Polaris-Frame--hasNav .Polaris-Frame__Main {
		padding-left: calc(var(--navigation-sidebar-width) + env(safe-area-inset-left));
		transition: padding-left var(--mainmenu-transition-time-content, 0.5s);
	}
}

.Polaris-TopBar-SearchField__Icon svg,
.Polaris-TopBar-SearchField__Input::placeholder,
.Polaris-TopBar-SearchField__Input {
	fill: var(--color-secondary);
	color: var(--color-secondary);
}

@media (max-width: 769px) {
	body {
		--navigation-sidebar-width: 0px;
	}
}

// .Polaris-Frame__Navigation--visible {
// 	--navigation-sidebar-width: 100px !important;
// }
