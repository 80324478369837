$borderColor: #c4cdd5;

.table_wrapper {
	border-spacing: 0px;
	border-collapse: collapse;
	// border: 1px solid $borderColor;
	min-width: 100%;

	th {
		text-align: left;
		border-style: none;
		border-color: $borderColor;
		border-width: 1px 1px 0px 1px;
		padding: 5px;
	}

	td {
		border-style: solid;
		border-color: $borderColor;
		border-width: 0px;
		padding: 5px;
	}
}
