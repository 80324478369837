.MainMenuTriggerTooltip_wrapper {
	position: relative;
	width: 100%;
}

.MainMenuTriggerTooltip-content {
	position: fixed;
	background-color: var(--secondary);
	border-radius: $borderRadius;
	box-shadow: $boxShadow;
	// display: none;
	visibility: hidden;
	opacity: 1;
	transform: translateX(-25%);
	transition: opacity 100ms, transform 250ms;
	pointer-events: none;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;

	&.open {
		visibility: visible;
		// display: initial;

		opacity: 1;
		transform: translateX(0);
		z-index: 999999999;
	}
}

.MainMenuTriggerTooltip_wrapper {
	> .mainmenu-item {
		padding: 0;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}
