#root {
	.Polaris-Page-Header__BreadcrumbWrapper {
		align-items: center;
		display: flex;
	}
	.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions {
		.Polaris-IndexTable-ScrollContainer {
			padding-top: 36px;
		}
	}

	.Polaris-IndexTable__Table.Polaris-IndexTable__Table--sticky {
		.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first {
			.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
				padding-left: 1rem;
			}
		}
	}

	.Polaris-Avatar__Initials {
		.Polaris-Avatar__Text {
			fill: #000000;
		}
	}

	.Polaris-Backdrop--belowNavigation {
		z-index: 90;
	}

	.Polaris-DatePicker__Title {
		text-transform: capitalize;
	}

	@media (min-width: 48.0625em) {
		.Polaris-Navigation__PrimaryNavigation {
			padding-top: 0;
		}
	}

	.Polaris-DropZone {
		transition: background-color 250ms;
		background-color: var(--secondary);

		&:hover {
			background-color: var(--tertiary);
		}

		.Polaris-DropZone-FileUpload__Action {
			--p-color-bg-interactive-subdued: var(--accent);
			--p-color-text-interactive: var(--color-dark);
		}
	}

	.Polaris-Button.Polaris-Button--primary {
		--p-color-icon-on-color: var(--color-dark);
	}
}

.modal-top {
	.Polaris-Modal-Dialog__Container {
		z-index: 500001 !important;
	}
	.Polaris-Backdrop {
		z-index: 500000 !important;
	}
}

.sheet-600 {
	.Polaris-Sheet__Container,
	.Polaris-Sheet {
		width: 600px;
	}
}

.Polaris-BulkActions__BulkActionButton {
	.Polaris-Button {
		background-color: var(--accent);
		color: #000000;
	}
}

.Polaris-Page {
	.Polaris-Card {
		margin-top: 1rem;
		background-color: var(--secondary);

		.Polaris-TextField__Backdrop {
			--p-color-bg: var(--primary);
		}
	}
}

.Polaris-TextField--readOnly {
	--p-color-border-interactive-focus: transparent;
}
.searchField {
	min-width: 200px;
}

// .Polaris-PositionedOverlay {
.Polaris-Popover:not(.Polaris-Popover--fullWidth) {
	max-width: 350px;
}
