$blue: #3d7fb6;
$lightblue: #5696ca;
$green: #4ba669;
$orange: #e1a34f;
$red: #e15f5f;
$pink: #cd67a5;
$yellow: #ab8a0a;
$yellow2: #e1bd5d;
$purple: #9463d1;
$grey: #a4a4a4;
$white: #ffffff;

:export {
	blue: $blue;
	lightblue: $lightblue;
	green: $green;
	orange: $orange;
	red: $red;
	pink: $pink;
	yellow: $yellow;
	yellow2: $yellow2;
	purple: $purple;
	grey: $grey;
	textColor: #e7e7e7;
	sidebar: #34cfc0;

	accent: #eff176;
	accent-hover: #f4f660;
	accent-subdued: #c4c667;
	subdued: #09272b;
	primary: #061d20;
	secondary: #0c3237;
	secondary-50: #0c323778;
	tertiary: #0e3d43;
	color-primary: #e7e7e7;
	color-secondary: #b4acac;
	color-subdued: #807a7a;
	color-dark: rgb(10, 10, 10);
	color-link: #6eafe4;
}

body {
	--mainmenu-transition-time: 250ms;
	--mainmenu-transition-time-content: 350ms;
	--navigation-sidebar-width: 100px;

	button:not(.Polaris-Button--plain) {
		--p-color-text-interactive-active: #ffffff;
		--p-color-text-interactive-hover: #ffffff;
	}
}

:root {
	//Our colors
	--accent: #eff176;
	--accent-hover: #f4f660;
	--accent-subdued: #c4c667;
	--accent-subdued2: #90924c;
	--subdued: #09272b;
	--primary: #061d20;
	--secondary: #0c3237;
	--secondary-50: #0c323778;
	--tertiary: #0e3d43;
	// quaternary, quinary, senary, septenary, octonary, nonary, denary.

	--color-primary: #e7e7e7;
	--color-secondary: #b4acac;
	--color-subdued: rgb(128, 122, 122);
	--color-dark: rgb(10, 10, 10);
	--color-link: #6eafe4;
	--textColor: var(--color-primary);
	//#shopify colors

	//background colors

	--p-color-bg: var(--primary);
	--p-color-bg-hover: var(--tertiary);
	--p-color-bg-app: var(--primary);
	--p-color-bg-app-active: var(--tertiary);
	--p-color-bg-app-hover: var(--tertiary);
	--p-color-bg-disabled: var(--tertiary);
	--p-color-bg-info-strong: var(--tertiary);
	--p-color-bg-info-subdued-active: var(--tertiary);
	--p-color-bg-info: var(--tertiary);
	--p-color-bg-info-subdued: var(--tertiary);
	--p-color-bg-interactive-selected: var(--primary);
	--p-color-bg-interactive: var(--tertiary);
	--p-color-bg-subdued: var(--secondary);
	--p-color-bg-primary: var(--accent);
	--p-color-bg-interactive-subdued-hover: var(--tertiary);
	--p-color-bg-subdued-active: var(--tertiary);
	--p-color-bg-critical-subdued: var(--tertiary);
	--p-color-bg-interactive-subdued-active: var(--accent-subdued);
	// --p-color-bg-strong-hover: var(--tertiary);
	// --p-color-bg-strong-active: var(--tertiary);
	// --p-color-bg-strong: var(--tertiary);
	--p-color-bg-interactive-disabled: var(--tertiary);
	--p-color-bg-app-selected: var(--tertiary);
	--p-color-bg-active: var(--tertiary);
	--p-color-bg-subdued-hover: var(--tertiary);
	--p-color-bg-inset: var(--tertiary);
	--p-color-bg-input: var(--tertiary);
	--p-color-bg-info-subdued-hover: var(--tertiary);

	//text colors
	--p-text: var(--color-primary);
	--p-color-text-interactive: var(--color-link);
	--p-color-text: #e7e7e7;
	--p-color-text-subdued: #b7b4b4;
	--p-color-bg-primary-subdued-hover: var(--accent);
	--p-color-text-primary: var(--color-dark);

	//border colors
	--p-color-border-input: var(--tertiary);
	--p-color-border-hover: var(--tertiary);
	--p-color-border-strong: var(--tertiary);
	--p-color-border: var(--tertiary);
	--p-color-border-disabled: var(--tertiary);
	--p-color-border-subdued: var(--tertiary);
	--p-color-border-interactive-disabled: var(--tertiary);
	--p-color-border-interactive-focus: var(--accent-subdued);

	//icons
	--p-color-icon: var(--color-secondary);
	--p-color-icon-hover: var(--color-primary);

	.Polaris-Box {
		--pc-box-background: var(--secondary);
		--pc-box-border-radius: 6px;
		--pc-box-border-color: var(--secondary) !important;
	}

	input {
		color-scheme: dark;
	}

	.Polaris-Page {
		& > .Polaris-Box {
			&,
			.Polaris-Box {
				--pc-box-background: transparent;
			}
		}
	}

	.Polaris-IndexTable__TableRow {
		--p-color-bg: var(--secondary);
		border: none;

		// &.Polaris-IndexTable__TableRow--hovered {
		// 	--p-color-bg: var(--tertiary);
		// }

		// &.Polaris-IndexTable__TableRow--striped {
		// 	--p-color-bg: var(--secondary-50);
		// }
	}

	.highcharts-background {
		fill: var(--secondary);
	}

	.highcharts-grid.highcharts-yaxis-grid path {
		stroke: var(--tertiary);
	}

	.Polaris-AlphaTabs__Tab {
		--p-color-bg: var(--secondary);
	}

	.Polaris-IndexFilters-Container {
		--p-color-border-subdued: transparent;
	}

	.Polaris-TextField__Backdrop {
		border-top-color: var(--p-color-border-input);
		border-color: transparent;
	}

	.Polaris-Card {
		// --p-color-bg: var(--secondary);

		// .Polaris-TextField__Backdrop {
		// 	--p-color-bg: var(--primary);
		// }
	}
	.Polaris-TextField__Backdrop {
		--p-color-bg: var(--tertiary);
	}

	.Polaris-Layout__Annotation .Polaris-TextContainer {
		.Polaris-Box {
			--pc-box-background: transparent;
		}
	}

	.Polaris-Badge {
		&.Polaris-Badge--statusSuccess,
		&.Polaris-Badge--statusAttention,
		&.Polaris-Badge--statusNew,
		&.Polaris-Badge--statusWarning,
		&[class="Polaris-Badge"] {
			--p-color-text: var(--color-dark);
			color: var(--color-dark);
		}
	}

	#PolarisPortalsContainer {
		.Polaris-Modal-Dialog__Modal {
			background: var(--secondary);
		}
		// .Polaris-Box {
		// 	--pc-box-border-radius: none;
		// }
	}

	.Polaris-Button {
		--p-color-bg: var(--tertiary);
	}

	.Polaris-Button--primary {
		--p-color-text-on-color: var(--color-dark);
		box-shadow: none;

		&:not(.Polaris-Button--destructive):hover {
			background: var(--accent-hover);
			border-color: var(--accent-hover);
		}

		&.Polaris-Button--disabled {
			background: var(--accent-subdued);
			border-color: var(--accent-subdued);
		}
	}

	.Polaris-Modal-Dialog__Modal {
		& > .Polaris-Box {
			// --pc-box-background: var(--secondary) !important;

			.Polaris-Modal-CloseButton {
				&:hover {
					background-color: transparent;
				}
			}
		}
	}

	.Polaris-Checkbox__Backdrop {
		--p-color-border-input: var(--tertiary);
		--p-color-bg: var(--secondary);
	}

	.Polaris-IndexTable__ZebraStriping .Polaris-IndexTable__TableRow:nth-child(2n + 1) {
		background: var(--tertiary);
	}

	.Polaris-Select__Backdrop {
		--p-color-bg: var(--tertiary);
		--p-color-border-input: var(--tertiary);
	}

	.Polaris-IndexTable__TableRow {
		--p-color-bg-interactive-subdued-hover: var(--primary);
		--p-color-bg-hover: var(--primary);

		&.Polaris-IndexTable__TableRow--selected {
			--p-color-bg-subdued: var(--primary);
			background: var(--primary) !important;
			background-color: var(--primary) !important;
		}

		&.Polaris-IndexTable__TableRow--hovered {
			--p-color-bg-subdued: var(--primary);
			background: var(--primary) !important;
			background-color: var(--primary) !important;
		}

		.Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
			transition: none;
		}

		.Polaris-IndexTable__TableCell:first-child,
		.Polaris-IndexTable__TableCell:last-child {
			background: transparent !important;
		}
	}

	.Polaris-IndexTable__TableHeadingSortButton {
		svg {
			fill: var(--color-primary);
		}
	}

	.Polaris-Banner {
		color: var(--color-dark);

		&.Polaris-Banner--withinContentContainer {
			color: var(--color-primary);
		}

		&.Polaris-Banner--statusInfo {
			color: var(--color-primary);
		}

		.Polaris-Box {
			--pc-box-background: transparent;
		}
	}

	.Polaris-Button {
		--p-border-radius-1: 1000px;
	}

	.Polaris-Tag {
		--p-color-text: var(--color-dark);
	}

	.Polaris-LegacyCard {
		--p-color-bg: var(--secondary);
	}

	.Polaris-Popover .Polaris-Box {
		--pc-box-background: var(--tertiary);
	}

	.Polaris-Icon--applyColor {
		--p-color-bg: var(--color-primary);
	}

	.Polaris-SortButton-DirectionButton {
		color: var(--color-primary);
	}

	.Polaris-SortButton-DirectionButton.Polaris-SortButton-DirectionButton__DirectionButton--active {
		--p-color-bg-interactive-selected: var(--secondary);
	}

	.Polaris-AlphaFilters-FilterPill__PopoverWrapper {
		.Polaris-TextField__Backdrop {
			--p-color-bg: var(--secondary);
		}
	}
	.filter-search {
		background: var(--secondary);
	}

	button:not(.Polaris-Button--disabled) {
		.Polaris-Button__Text.Polaris-Button--removeUnderline {
			color: var(--color-primary);
		}
	}

	.Polaris-Spinner--sizeSmall {
		&,
		svg {
			height: 1rem;
			width: 1rem;
		}
	}

	.Polaris-Checkbox__Backdrop::before {
		--p-color-border-disabled: var(--accent-subdued);
		--p-color-bg-interactive-disabled: var(--accent-subdued);
		--p-color-border-interactive: var(--accent);
		--p-color-bg-interactive: var(--accent);
	}

	.Polaris-RadioButton__Input[checked] + .Polaris-RadioButton__Backdrop,
	.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
		--p-color-border-disabled: var(--accent-subdued);
		--p-color-bg-interactive-disabled: var(--accent-subdued);
		--p-color-border-interactive: var(--accent);
		--p-color-bg-interactive: var(--accent);
	}
	.Polaris-Checkbox__Icon svg {
		--p-color-icon-on-color: var(--color-dark);
	}

	.Polaris-DatePicker {
		--p-color-bg-interactive: var(--accent-subdued);
		--p-color-bg-interactive-hover: var(--accent-subdued);
		--p-color-text-on-color: var(--color-primary);

		// .Polaris-DatePicker__Day.Polaris-DatePicker__Day--today {
		// 	background-color: var(--accent-subdued2);
		// }
	}

	.Polaris-Sheet {
		--p-color-bg: var(--secondary);
	}
}

$sidebarColorBg: var(--secondary);
$sidebarColorAccent: var(--accent);
$sidebarColor: var(--color-primary);
$navSidebarWidth: var(--navigation-sidebar-width);
$borderRadius: 0.7rem;
$boxShadow: 1px 4px 12px 0px #00000030;
$boxShadowSmall: 1px 3px 4px 0px #00000030;
$main3: var(--tertiary);
$main2-50: var(--secondary-50);
$main2: var(--secondary);
$main1: var(--primary);
$textColor: var(--textColor);
$buttonPadding: 0.7rem 1.6rem;
