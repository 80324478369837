.editor {
	position: relative;

	.editor-scroll {
		padding-top: 125px;
		overflow: auto;

		.editor-scroll__inner {
			padding-left: 50px;
			padding-bottom: 50px;
		}

		.canvas-wrapper {
			position: relative;
			margin: 0 auto;
			width: min-content;

			&.disabled {
				pointer-events: none;
			}

			.canvas-container {
				margin: 0 auto;
				transition: all 150ms;
				> canvas {
					transition: all 150ms;
				}
			}
			.canvas-button-sidebar {
				top: 2px;
				left: 0;
				position: absolute;
				transform: translate(-150%, 0);
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 1rem;

				.canvas-wrapper__remove-page {
					border-radius: 5px;
					cursor: pointer;

					svg {
						transition: fill 250ms;
						fill: $main3;
					}

					&:hover {
						svg {
							fill: $red;
						}
					}
				}
			}
		}
	}

	.editor__toolbar__active-object {
		gap: 0.5rem;

		input[type="color"] {
			padding: 0;
			border: none;
			height: 36px;
		}

		.remove-object-button {
			.Polaris-Icon {
				width: 1.5rem;
				height: 1.5rem;

				svg path {
					fill: white;
				}
			}
		}

		.font-size__wrapper {
			.Polaris-Connected__Item.Polaris-Connected__Item--primary {
				width: 80px;
			}

			.Polaris-TextField__Prefix {
				height: 18px;
				width: 15px;

				img {
					width: 15px;
					height: 15px;
				}
			}
		}
	}

	.editor__toolbar {
		background: $main2-50;
		padding: 1rem;
		gap: 1rem;
		display: flex;
		flex-direction: column;
		position: fixed;
		width: 100%;
		box-shadow: 0px 5px 10px 0px #0000004f;
		backdrop-filter: blur(25px);
		left: 0;
		z-index: 1;
		padding-left: calc(var(--navigation-sidebar-width) + env(safe-area-inset-left));
		transition: padding-left var(--mainmenu-transition-time);

		.Polaris-Button:not(.Polaris-Button--destructive):not(.Polaris-Button--primary) svg,
		.Polaris-Button:not(.Polaris-Button--destructive):not(.Polaris-Button--primary) path {
			fill: #637381;
		}

		button.Polaris-Button.Polaris-Button--pressed {
			filter: brightness(1.5);
		}

		.Polaris-ButtonGroup {
			margin: 0;

			.Polaris-ButtonGroup__Item {
				margin: 0;

				button {
					border-radius: 0;
				}

				&:last-child button {
					border-top-right-radius: 2px;
					border-bottom-right-radius: 2px;
				}

				&:first-child button {
					border-top-left-radius: 2px;
					border-bottom-left-radius: 2px;
				}
			}
		}

		> div {
			display: flex;
			gap: 3rem;
			justify-content: center;
			width: 100%;
			align-items: center;
			position: relative;
		}

		.Polaris-Select__Content {
			background: $main3;
		}

		.editor__toolbar__rightButtons {
			position: absolute;
			right: 2rem;
			display: flex;
			align-items: center;
			gap: 2rem;
		}

		.editor__toolbar__leftButtons {
			position: absolute;
			left: 2rem;
			display: flex;
			align-items: center;
			gap: 2rem;
		}

		.editor__toolbar__buttonGroup {
			background: $main3;
			border-radius: 25px;
			box-shadow: $boxShadow;
			padding: $buttonPadding;
			display: flex;
			align-items: center;
			gap: 0.5rem;

			button {
				background: transparent;
				border: none;
				cursor: pointer;
				color: $textColor;
				opacity: 0.75;
				padding: 3px;
				transition: opacity 250ms, filter 250ms;

				&:not(:disabled):hover {
					opacity: 1;
					filter: brightness(1.2);
				}

				&:disabled {
					opacity: 0.5;
					cursor: unset;
				}
			}

			.nr {
				color: $textColor;
			}

			.zoom-icon .Polaris-Icon {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}
.embedded-editor {
	.editor__toolbar {
		width: 77rem;
		left: auto;
		right: 0;
		top: 0;
		padding-left: 1rem;
		padding-right: 3rem;
		.editor__toolbar__leftButtons,
		.editor__toolbar__newPageBtn {
			display: none;
		}
	}
	.editor-scroll__inner {
		padding-left: 0 !important;
	}
	.canvas-button-sidebar {
		display: none !important;
	}
}
.remove-page__button-wrapper {
	padding: 1rem;
	border-radius: $borderRadius;
}

.varible-row {
	display: flex;
	gap: 1rem;
	& > .key,
	& > .value {
		width: 50%;
	}
}
.pdf-pages-overview {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	// justify-content: space-between;

	.page-tumbnail {
		width: 200px;
		cursor: pointer;
		box-shadow: $boxShadowSmall;

		img {
			width: 100%;
		}
	}
}

.temp-hidden-canvas-wrapper {
	& > * {
		display: none;
	}
}

.close-button {
	position: absolute;
	right: 0;
	top: 0;
	background: none;
	border: none;
	padding: 5px;
	cursor: pointer;
	opacity: 0.75;

	&:hover {
		opacity: 1;
	}

	.Polaris-Icon {
		height: 1.5rem;
		width: 1.5rem;
	}
}

.variable-popup {
	min-width: 300px;
}

.Polaris-Button--image-btn {
	button {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
		.Polaris-Button__Text,
		img {
			width: 20px;
			height: 20px;
		}
	}
}

.canvas-container-wrapper {
	border: 1px solid #80808052;
}
