.project_dashboard {
	display: grid;
	gap: 2rem;
	grid-template-areas: "header header" "graph graph" "pie list";
	// margin-bottom: 2rem;
	grid-template-rows: auto 1fr 1fr;
	grid-template-columns: 1fr 1fr;

	min-height: calc(100vh - calc(5.6rem + 40px + 84px));

	@media screen and (max-width: 1000px) {
		grid-template-areas: "header" "graph" "pie" "list";
		grid-template-rows: auto 1fr 1fr 1fr;
		grid-template-columns: 1fr;
	}

	.Polaris-Card {
		margin: 0 !important;
		height: 100%;

		.Polaris-Card__Section {
			height: 100%;
		}
	}

	.datefields {
		grid-area: header;
	}

	.graph {
		grid-area: graph;
	}

	.pie {
		grid-area: pie;
	}

	.list {
		grid-area: list;
		overflow: hidden;
		background-color: var(--primary);
		box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
		outline: 0.1rem solid transparent;
		position: relative;
		border-radius: var(--p-border-radius-wide, 3px);
		color: var(--color-primary);
	}

	.highcharts-label.highcharts-data-label text .highcharts-text-outline {
		display: none;
	}
}

.datefields {
	width: max-content;

	input[type="date"]::-webkit-inner-spin-button,
	input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}
}

.highcharts-credits {
	display: none;
}

.pie_inner {
	display: flex;
	gap: 2rem;
	flex: 1;
	justify-content: center;
	height: 100%;

	div[data-highcharts-chart] {
		overflow: unset !important;

		display: flex;
		align-items: center;
	}
}

.Polaris-Card {
	position: relative;
}
.Polaris-Card__Section {
	position: relative;
	overflow: auto;
}

.project_list {
	width: 100%;
	max-height: 100%;

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	padding: inherit;
}

.project_list {
	table {
		position: relative;
	}

	th {
		padding: 0.5rem;
		font-weight: 500;
	}

	thead {
		top: calc(var(--p-space-5) * -1);
		position: sticky;
		background-color: var(--secondary);
	}

	th,
	td {
		&:first-of-type {
			display: flex;
			justify-content: flex-start;
		}

		&:not(:first-of-type) {
			text-align: center;
		}

		&.name {
			color: var(--color-primary);
		}
	}
}
