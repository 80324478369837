@import "@shopify/polaris/build/esm/styles.css";
@import "./variables.scss";
@import "./mainmenu.scss";
@import "./tooltip.scss";
@import "./table.scss";
@import "./quotes.scss";
@import "./editor.scss";
@import "./calendar.scss";
@import "./projectDashboard.scss";
@import "./polaris.scss";

html,
body {
	height: 100%;
	background-color: var(--primary);
}

.saved_searches {
	.Polaris-Button,
	.Polaris-TextField {
		height: 53px;
	}
}

.Polaris-TopBar-UserMenu__Name {
	color: black;
}
.Polaris-TopBar-UserMenu__Detail {
	color: black;
}
.holder_inner2 {
	position: relative;
	top: -40px;
	-webkit-transition: 0.5s all ease;
	transition: 0.5s all ease;
	min-height: 80px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding-top: 20px;
	width: 80px;
	.Polaris-Icon {
		pointer-events: none;
		.Polaris-Icon__Svg {
			fill: white;
			margin-top: 10px;
		}
	}
	&.loading {
		opacity: 0.5;
	}
	&:hover {
		top: -50px;
	}
	&.compact {
		min-height: 40px;
		height: 40px;
		width: 40px;
		top: -28px;
		padding-top: 1px;
	}
}
.holder_inner2_right {
	position: relative;
	top: -80px;
	right: 10px;
	pointer-events: none;
	text-align: right;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	font-size: 30px;
	&.compact {
		font-size: 25px;
		top: -50px;
	}
}

.jss653 {
	color: #3c4858;
	min-height: auto;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	font-size: 20px;
	margin-bottom: 3px;
	text-decoration: none;
}
.jss652 {
	color: #3c4858;
	margin-top: -22px;
	min-height: auto;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	font-size: 20px;
	margin-bottom: 3px;
	text-decoration: none;
	&.compact {
		font-size: 16px;
		margin-top: -26px;
	}
}
.jss655 {
	color: #999;
	margin: 0;
	font-size: 14px;
	margin-top: 0;
	padding-top: 10px;
	margin-bottom: 0;
}
.alltid {
	.Polaris-Button {
		float: right;
		margin-top: -39px;
	}
	.Polaris-Heading {
		margin-bottom: 25px;
	}
}
.dashboard {
	.Polaris-Card {
		overflow: visible;
		margin-top: 25px;
	}
	.top_section {
		width: 100%;
		height: 84px;
		padding: 0 var(--p-space-6);
	}
	.Polaris-Tabs {
		border-bottom: 0;
		margin-top: 15px;
	}
	.Polaris-Layout {
		margin-top: 15px;
	}
}
// .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
// 	border-bottom: var(--p-override-none, 0.3rem) solid var(--secondary);
// }
.blue_gradient {
	background: linear-gradient(60deg, #26c6da, #00acc1);
}
.orange_gradient {
	background: linear-gradient(60deg, #ffa726, #fb8c00);
}
.pink_gradient {
	background: linear-gradient(60deg, #ec407a, #d81b60);
}

.settings-item {
	max-width: 270px;
	// font-size: 1.4rem;
	font-weight: 400;
	// line-height: 2rem;
	text-transform: initial;
	letter-spacing: initial;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.5rem;
	border: 0;
	color: var(--color-primary);
	cursor: pointer;
	border-radius: 3px;
	text-decoration: none;
	transition: background-color 250ms, filter 250ms;

	.icon-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 4rem;
		height: 4rem;
		margin-right: 1.6rem;
		border-radius: 3px;
		background-color: var(--tertiary);
		fill: #919eab;
		transition: background-color 250ms, filter 250ms;
	}
	p {
		font-weight: 600;
		margin: 0;
		color: var(--color-primary);
	}
	span {
		color: var(--color-secondary);
		margin: 0;
		display: block;
	}
	&:hover {
		background-color: var(--tertiary);
		.icon-wrapper {
			background-color: var(--tertiary);
			filter: brightness(1.25);
		}
	}
}
.relative {
	position: relative;
	.Polaris-Scrollable {
		padding: 1px;
		padding-bottom: 20px;
	}
}
.shadow-overlay {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
}
.Polaris-Scrollable--hasLeftShadow {
	box-shadow: inset 2rem 0 2rem -2rem rgba(0, 0, 0, 0.1);
	border-left: 1px solid #eee;
}
.Polaris-Scrollable--hasRightShadow {
	box-shadow: inset -2rem 0 2rem -2rem rgba(0, 0, 0, 0.1);
	border-right: 1px solid #eee;
}
.Polaris-Scrollable--hasRightShadow.Polaris-Scrollable--hasLeftShadow {
	box-shadow: inset -2rem 0 2rem -2rem rgba(0, 0, 0, 0.1), inset 2rem 0 2rem -2rem rgba(0, 0, 0, 0.1);
}

$vertical-padding: (control-height() - line-height(body)) / 2;
$horizontal-padding: 0;
$icon-size: rem(20px);

// $vertical-padding: (control-height() - line-height(body)) / 2;
// $horizontal-padding: spacing(tight);
// $icon-size: rem(20px);

$difference-between-touch-area-and-backdrop: 0;
// $difference-between-touch-area-and-backdrop: control-height() - control-slim-height();

$stacking-order: (
	focus-backdrop: 10,
	content: 20,
);

.Polaris-TopBar__LogoContainer {
	a {
		position: relative;
		height: 100%;

		img {
			height: 100%;
			padding: 0.75em;
		}
	}
}

.Polaris-Page-Header__Navigation a {
	position: relative;
	display: inline-flex;
	align-items: center;
	min-height: control-height();

	// We only have to compensate for half the space on the left because
	// The icon has half the space to the focus backdrop as the text does
	// on the right side. We have to do negative margin on top and bottom
	// to compensate for the focus backdrop being smaller than the actual
	// hit area.
	// prettier-ignore
	margin:
    (-0.5 * $difference-between-touch-area-and-backdrop)
    (-1 * $horizontal-padding)
    (-0.5 * $difference-between-touch-area-and-backdrop)
    (-0.5 * $horizontal-padding);
	padding: $vertical-padding $horizontal-padding;
	color: color("ink", "lighter");
	text-decoration: none;

	&:hover,
	&:active {
		color: color("ink");
		text-decoration: none;
	}

	&:focus {
		outline: none;

		&::after {
			background: plain-button-background();
		}
	}

	&::after {
		content: "";
		position: absolute;
		// z-index: z-index(focus-backdrop, $stacking-order);
		top: 0.5 * $difference-between-touch-area-and-backdrop;
		right: 0;
		left: 0;
		display: block;
		height: control-slim-height();
		border-radius: border-radius();
		will-change: background;
		transition: background duration() easing();
	}
}
.sales_pipe {
	> .Polaris-Stack {
		> .Polaris-Stack__Item {
			min-width: 272px;
			min-width: 300px;
		}
	}
}
.pipe {
	&.dark {
		> .Polaris-Card {
			> .Polaris-Card__Header {
				.Polaris-Heading {
					color: #ffffff;
				}
			}
		}
	}
	border-radius: 3px;
	> .Polaris-Card {
		background-color: transparent;
	}
	&.empty {
		.Polaris-Card__Section {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
	.Polaris-ResourceList {
		background-color: rgba(255, 255, 255, 0.5);
	}
	.Polaris-ResourceList__HeaderContentWrapper {
		left: 6px;
	}
	.Polaris-ResourceList__BulkActionsWrapper {
		left: -10px;
	}
	.Polaris-ResourceList-Item__Content {
		margin-left: -20px;
	}
}
.truncate {
	position: relative;
	overflow: hidden;
	&::after {
		background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
		background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -moz-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
		background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -ms-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
		background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff), -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);

		content: " ";
		height: 100%;
		position: absolute;
		right: 0px;
		top: 0;
		width: 25px;
		z-index: 10;
	}
	* {
		white-space: nowrap;
	}
}
.Polaris-Scrollable--hasLeftShadow {
	box-shadow: inset 2rem 0 2rem -2rem rgba(0, 0, 0, 0.1);
	border-left: 1px solid #eee;
}
.Polaris-Scrollable--hasRightShadow {
	box-shadow: inset -2rem 0 2rem -2rem rgba(0, 0, 0, 0.1);
	border-right: 1px solid #eee;
}
.Polaris-Scrollable--hasRightShadow.Polaris-Scrollable--hasLeftShadow {
	box-shadow: inset -2rem 0 2rem -2rem rgba(0, 0, 0, 0.1), inset 2rem 0 2rem -2rem rgba(0, 0, 0, 0.1);
}
.datatable {
	.Polaris-ResourceList {
		display: none;
	}
	tbody th,
	tbody td {
		border-bottom: 1px solid rgb(223, 227, 232);
	}
	.Polaris-DataTable__Cell--header {
		border-bottom: 0.1rem solid #f4f6f8;
	}
	.Polaris-DataTable__Navigation {
		//display:none;
		justify-content: flex-end !important;
	}
	thead th {
		border-top: 0;
		min-height: 53px;
	}

	.Polaris-Choice__Control {
		margin-right: 0px;
	}
	&.mm {
		.Polaris-Choice__Control {
			margin-right: 20px;
		}
	}

	.Polaris-ResourceList__HeaderOuterWrapper,
	.Polaris-ResourceList__HeaderWrapper {
		background-color: transparent;
		height: 54px;
		overflow: hidden;
		white-space: nowrap;
		z-index: 21;
	}
	&.haveactions {
		thead {
			th:first-child {
				width: 40px;
			}
		}
		tbody {
			th:first-child {
				padding-left: 25px;
				width: 40px;
			}
			td:nth-child(2) {
				white-space: normal;
			}
		}
	}
	&.havemedia:not(.haveactions) {
		td:first-child {
			width: 40px;
			padding-left: 0;
			padding-right: 0;
		}
		td:nth-child(2) {
			white-space: normal;
		}
	}
	&.havemedia.haveactions {
		td:nth-child(2) {
			width: 40px;
			padding-left: 0;
			padding-right: 0;
		}
		td:nth-child(3) {
			white-space: normal;
		}
	}
	&.haveselection {
		.Polaris-ResourceList__HeaderOuterWrapper,
		.Polaris-ResourceList__HeaderWrapper {
			background-color: white;
			width: 100%;
		}
		.Polaris-DataTable__Icon {
			visibility: hidden;
		}
	}
	.Polaris-ResourceList__HeaderWrapper--isSticky {
		background-color: #fff;
		width: auto;
	}
}
.columnSelectorWrapper {
	display: flex;
	border-bottom: 0.1rem solid #dfe3e8;
	> div {
		display: flex;
	}
}
a {
	appearance: none;
	display: inline;
	text-align: inherit;
	padding: 0;
	background: none;
	border: none;
	font-size: inherit;
	color: $blue;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		outline: none;
		color: color("blue", "dark");
	}
}
.variantList {
	margin-top: 10px;
	border-top: 1px solid #eee;
}
.variantList ul li {
	background-color: #fff;
}
.variantList ul li:hover {
	background-color: $blue;
	color: #fff;
}
.editableCell {
	position: relative;
	margin-top: -1.6rem;
	margin-right: -1.6rem;
	margin-bottom: -1.6rem;
	> div {
		padding-top: 1.6rem;
		padding-right: 1.6rem;
		padding-bottom: 1.6rem;
	}
	.Polaris-TextField__Prefix {
		margin-left: 0;
	}
	.Polaris-TextField__Backdrop {
		display: none;
	}
}

.uploadContainer {
	position: relative;
	.uploadOverlay {
		-webkit-transition: opacity 0.2s ease-in-out;
		-moz-transition: opacity 0.2s ease-in-out;
		transition: opacity 0.2s ease-in-out;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #000;
		z-index: 11;
	}
	.uploadControls {
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 20;
		padding-bottom: 10px;
		> div {
			margin: 0 auto;
			display: inline-block;
		}
	}
	&:hover {
		.uploadOverlay {
			opacity: 0.5;
		}
		.uploadControls {
			display: block;
			button {
				color: #fff;
				fill: #fff;
			}
		}
	}
}
.Polaris-DropZone__Container > .Polaris-Stack > .Polaris-Stack__Item:first-child .uploadContainer .Polaris-Thumbnail {
	width: 18.5rem;
}
.customersel .Polaris-ResourceList__HeaderWrapper--hasSelect {
	display: none;
}
.timeline {
	.timeline-header {
		margin-bottom: 20px;
		border-bottom: 1px solid #d3dbe2;
		padding: 20px;
	}
	.timeline-rows {
		position: relative;
		.timeline-new {
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			margin-bottom: 40px;
			.Polaris-TextField {
				padding: 5px;
				padding-left: 0;
				.Polaris-TextField__Suffix {
					margin-right: 5px;
					pointer-events: auto;
					.Polaris-Button--plain {
						position: relative;
						top: 4px;
					}
				}
			}
		}
		.timeline-row {
			position: relative;
			margin-bottom: 40px;
			.timeline-ball {
				background-color: #4eb4a9;
				border-radius: 50%;
				height: 23px;
				width: 23px;
				position: relative;
				top: 0px;
				left: 8px;
				border: 3px solid #ebeef0;
				z-index: 2;
			}
			.timeline-content {
				position: relative;
				.Polaris-Button--plain {
					position: absolute;
					top: 0;
					right: 0;
				}
			}
		}
		.Polaris-Avatar,
		.timeline-card-wrapper {
			z-index: 2;
			position: relative;
		}
		.timeline-row:not(:last-child):after,
		.timeline-new:not(:last-child):after {
			content: " ";
			position: absolute;
			top: 20px;
			bottom: -40px;
			left: 38px;
			width: 3px;
			z-index: 1;
			background: #e3e6e9;
		}
		.timeline-new > .Polaris-Stack > .Polaris-Stack__Item:first-child,
		.timeline-card-wrapper > .Polaris-Card > .Polaris-Card__Section > .Polaris-Stack > .Polaris-Stack__Item:first-child {
			max-width: 40px;
		}
	}
}
.order_items {
	.Polaris-ResourceList__HeaderOuterWrapper {
		> div {
			> div {
				padding-bottom: 0 !important;
				position: relative !important;
				top: 0 !important;
				left: 0 !important;
				width: auto !important;
				.Polaris-ResourceList__HeaderWrapper--isSticky {
					box-shadow: none !important;
				}
			}
		}
	}
}
.dropshipping_catalog {
	.categories {
		padding-bottom: 20px;
		.after {
			clear: both;
		}
	}
	.Polaris-Layout__Section--secondary {
		flex: 1 1 10rem;
	}
	.Polaris-ActionList {
		padding-top: 0;
		.Polaris-ActionList__Actions {
			clear: left;
		}
		.Polaris-ActionList__Actions li {
			float: left;
			max-width: 33.333334%;
		}
	}
}
.prop {
	display: inline-block;
	margin-right: 10px;
	color: lightgreen;
}
.prop:nth-child(2) {
	color: lightblue;
}
.prop:nth-child(3) {
	color: lightred;
}
.Polaris-Popover__Content {
	max-width: 60rem;
	max-height: 40rem;
}
.table {
	width: 100%;
	thead {
		tr {
			th {
				text-align: left;
				padding: 5px 20px;
			}
		}
	}
	tbody {
		tr {
			td {
				border-top: 1px solid #f9f9f9;
				padding: 5px 20px;
			}
		}
	}
}
.Polaris-DropZone {
	min-height: 0;
	height: 100%;
	width: 100%;
}

.CustomerListItem__Title {
	font-weight: 600;
}

.CustomerListItem__Orders {
	display: flex;
	align-items: center;
	list-style: none;
	gap: 0.5rem;
}

.CustomerListItem__Orders > *:not(:first-child)::before {
	content: "•";
	margin: 0 4px;
	color: #919eab; /* ink, lightest */
}

.CustomerListItem__OrderCount {
	white-space: nowrap;
}

.CustomerListItem__TotalSpent {
	display: flex;
	min-width: 0;
	justify-content: flex-end;
}

.CustomerListItem__Exceptions {
	margin-top: 4px;
}

.CustomerListItem__ConditionalActions {
	margin-top: 4px;
}

@media (min-width: 640px) {
	.CustomerListItem__Main {
		display: flex;
	}

	.CustomerListItem__Main > *:not(:last-child) {
		margin-right: 20px;
	}

	.CustomerListItem__Orders {
		flex: 1;
		justify-content: flex-end;
	}

	.CustomerListItem__Orders > *:not(:first-child)::before {
		display: none;
	}

	.CustomerListItem__OrderCount {
		min-width: 80px;
	}

	.CustomerListItem__TotalSpent {
		min-width: 168px;
	}
}

@media (min-width: 800px) {
	.CustomerListItem__Media {
		width: 32px;
	}

	.CustomerListItem__Profile {
		display: flex;
		flex-wrap: wrap;
	}

	.CustomerListItem__Profile > *:not(:last-child) {
		margin-right: 12px;
	}
}
.oneline,
.one-line {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hideScrollbar,
.hideScroll,
.noScrollbar,
.noScroll,
.noscroll {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}
.capitalize {
	text-transform: capitalize;
}
.Polaris-Tabs__Tab:focus {
	box-shadow: none;
}
.Polaris-DataTable__TableRow {
	cursor: pointer;
}

body[scrollLocked="true"] {
	overflow: hidden;
}

.Polaris-Page.Polaris-Page--fullWidth .Polaris-Page.Polaris-Page--fullWidth {
	padding: 0;

	.Polaris-Page-Header.Polaris-Page-Header--hasNavigation {
		padding: 0;
	}
}

.user-time_reports .Polaris-Page {
	padding: 0 !important;
}

@media (min-width: 65.25em) {
	.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
		max-width: 110rem;
	}
}

.Pdf-Viewer__Wrapper {
	max-width: 100%;
	height: auto;

	.Pdf-Viewer.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
	.Pdf-Viewer {
		margin: 2rem 0;
		.Pdf-Viewer__Content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			justify-items: stretch;
			column-gap: 2rem;
			row-gap: 2rem;
		}
	}
	.Polaris-ButtonGroup {
		justify-content: center;
	}
}

.Pdf-Viewer__Page {
	position: relative;
	.Pdf-Viewer__Page-Actions {
		transition: opacity 250ms;
		position: absolute;
		display: flex;
		gap: 1rem;
		opacity: 0;
		top: 1rem;
		right: 1rem;
	}
	&:hover {
		.Pdf-Viewer__Page-Actions {
			opacity: 1;
		}
	}
	&.BoxShadow {
		canvas {
			box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
		}
	}
}

.table_wrapper {
	&.Quote__Rows-Display-Table {
		thead {
			th {
				white-space: nowrap;
				width: auto;
			}
		}
	}

	thead,
	tbody {
		tr:not(.Quote-Table-Comment-Row),
		tr.disabled {
			td:last-child,
			th:last-child {
				text-align: right;
			}
		}
	}
}

// .Quote-Page {
// 	div.Polaris-Page {
// 		max-width: 110rem;
// 	}
// }

body {
	.rbc-today {
		background-color: var(--accent-subdued);
	}
	.rbc-calendar {
		&,
		* {
			border-color: var(--tertiary) !important;
		}
	}
	.rbc-time-header-content {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;

		&,
		* {
			border-color: rgba(247, 247, 247, 0.25) !important;
		}
	}
	.rbc-time-header-content,
	.rbc-day-slot.rbc-time-column {
		background: var(--tertiary);
	}

	.rbc-label.rbc-time-header-gutter {
		background-color: var(--primary);
	}

	.rbc-day-slot .rbc-time-slot {
		border-top-width: 0;
	}

	.rbc-time-slot {
		font-weight: 500;
	}

	.rbc-day-slot.rbc-time-column {
		.rbc-timeslot-group:first-of-type {
			color: red;
			.rbc-time-slot:first-of-type {
				border-top-width: 1px;
			}
		}
	}

	// .rbc-time-content {
	// 	& > div:nth-child(2) {
	// 		border-color: transparent !important;
	// 	}
	// }

	.rbc-time-view .rbc-allday-cell {
		display: none;
	}

	.rbc-time-view,
	.rbc-time-content,
	.rbc-time-gutter.rbc-time-column,
	.rbc-time-gutter.rbc-time-column * {
		border-color: transparent !important;
	}

	.rbc-day-slot.rbc-time-column {
		&,
		* {
			border-color: rgba(247, 247, 247, 0.25) !important;
		}

		&:last-of-type {
			border-bottom-right-radius: 6px;
		}
		&:first-of-type {
			border-bottom-right-radius: 6px;
		}
		.rbc-timeslot-group {
			border-left-color: transparent !important;
		}
	}

	.rbc-header a {
		pointer-events: none;
	}

	.rbc-toolbar button {
		color: var(--color-primary);
		transition: background 100ms;
		cursor: pointer;

		&:hover,
		&:active,
		&:focus,
		&:active:hover {
			background-color: var(--accent);
			// border-color: #adadad;
		}
	}

	.rbc-off-range-bg {
		background: var(--secondary-50);
	}

	.rbc-event {
		&:hover {
			filter: brightness(1.2);
		}
	}
}
